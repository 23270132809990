@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .field {
        @apply relative bg-white border-secondary_grey transition duration-300 rounded-lg;
    }

    .main {
        @apply h-screen relative bg-stone-50;
    }

    .main-logo {
        @apply absolute top-10 left-10 w-20 h-20;
    }

    .center-h {
        @apply flex items-center h-full;
    }

    .label {
        @apply absolute pointer-events-none text-sm bg-white duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] px-2;
    }

    .auth-input {
        @apply block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg
    }

    .btn {
        @apply w-full block text-white bg-theme text-center text-sm border-2 border-transparent hover:bg-transparent hover:border-2 hover:border-theme hover:text-theme rounded transition duration-500 py-2 lg:px-7 px-4
    }

    .main-view {
        @apply rounded-lg drop-shadow-lg bg-white px-6 py-8 mx-4 border;
    }
}