@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
.mainView {
    @apply absolute flex items-center justify-center w-8 h-8 rounded-full -start-4 ring-4 ring-white text-white dark:bg-gray-500
}
.stepFont {
    @apply font-normal leading-tight w-12 font-poppins text-sm
} 
}