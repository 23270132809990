.mySwiper .swiper-pagination {
  left: 20px;
  position: relative;
  top: -20px;
  display: flex;
}

.mySwiper .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  margin: 0 5px;
  background-color: rgba(6, 86, 153, 1);
}